.dropdown-menu li a{

    text-decoration: none;
    
   
   }

   .dropdown-menu li a:hover{

    text-decoration: none;
    color: #5142AB;
    font-weight: bold;
   
   }
   .input-group-append {
    cursor: pointer;
  }